<template>
    <div class="login">
        <vue-particles color="#fff" :particleOpacity="0.7" :particlesNumber="60" shapeType="circle" :particleSize="4"
            linesColor="#fff" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2"
            :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="lizi"> 22 </vue-particles>
        <div class="content">
            <h3 style="text-align:center;color:#707070;font-size:20px;font-weight:bold;margin-bottom:20px;">登录</h3>
            <loginComponent @success="success" @registry="registry"/>
        </div>
    </div>
</template>
<script>
// import identify from '@/components/identify.vue'
// import { Input, Icon, Row, Col, Button } from 'ant-design-vue';
// import { mapMutations } from 'vuex';
import { mapState } from "vuex";
import loginComponent from '@/pages/login/loginComponent.vue'
export default {
    name: 'login',
    components: {
        loginComponent
        // AInput: Input,
        // AIcon: Icon,
        // ARow: Row,
        // ACol: Col,
        // AButton: Button,
        // identify
        // VueParticles
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(["user"]),
    },
    mounted() {
        document.title = '创投后台管理系统'
        window.localStorage.setItem('token', '')
        // this.createCode()
    },
    methods: {
        success() {
            const path = this.$route.query.callbackUrl || (this.user.userType == 1 ? "/home" : "/project");
            this.$router.replace({
                path,
            });
        },
        registry() {
            this.$router.replace({
                path: "/registry",
            });
        }
    }
}
</script>
<style lang="less" scoped>
.login {
    height: 100vh;
    overflow: hidden;
    background-image: url('./../../assets/loginBac.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .content {
        width: 400px;
        height: 330px;
        border-radius: 10px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 20px 20px 5px 20px;
        box-sizing: border-box;
    }
}
</style>